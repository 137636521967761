import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const stockPriceSlice = createSlice({
  name: "stockPrice",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    drawer: false,
    apiError: {},
    listCount: 0,
    priceCount: 0,
    stockPriceList: [],
    stockPriceCount: {},
    stockPriceParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    commentList: [],
    galleryList: [],
    commentParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 5,
      page: 1,
      search: "",
    },
    galleryParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 5,
      page: 1,
      search: "",
    },

    commentCount: 0,
    galleryCount: 0,
    ratingViewModel: false,
    ratingList: [],
    ratingParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 5,
      page: 1,
      search: "",
    },
    ratingCount: 0,
    minimumSaleObj: {
      id: "",
    },
    qsPriceObj: {
      id: "",
    },
    miniModel: false,
    incentiveObj: {
      id: "",
    },
    incentiveModel: false,
    guarageModel: false,
    galleryModel: false,
    quickShellModel: false,
    guarageData: {},
    guarageLoading: false,
    quickSellLoading: false,
    guarageViewModel: false,
    guarageViewData: {},
    filterStatus: false,
  },
  reducers: {
    getStockPrice: (state) => {
      if (state.stockPriceParams?.page == 1) {
        return {
          ...state,
          loading: true,
          stockPriceList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    stockPriceSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.stockPriceParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.stockPriceParams?.page_size
      );
      return {
        ...state,
        stockPriceList: list,
        listCount: action.payload.count,
        loading: false,
        stockPriceParams: {
          ...state.stockPriceParams,
          no_of_pages: noofpages,
        },
      };
    },
    setStockPriceParams: (state, action) => {
      return {
        ...state,
        stockPriceParams: action.payload,
      };
    },

    getStockPriceCount: (state, action) => {
      return {
        ...state,
        // loading: true,
        stockPriceCount: {},
      };
    },
    stockPriceCountSuccessful: (state, action) => {
      return {
        ...state,
        stockPriceCount: action.payload.response,
      };
    },

    FilterInputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "counter__zone":
          return {
            ...state,
            stockPriceParams: {
              ...state.stockPriceParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter__division: null,
              counter: null,
            },
          };

        case "counter__division":
          return {
            ...state,
            stockPriceParams: {
              ...state.stockPriceParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter: null,
            },
          };

        case "counter__cluster":
          return {
            ...state,
            stockPriceParams: {
              ...state.stockPriceParams,
              [action.payload.key]: action.payload.value,
              counter: null,
            },
          };
        case "vehicle__version__brand":
          return {
            ...state,
            stockPriceParams: {
              ...state.stockPriceParams,
              [action.payload.key]: action.payload.value,
              vehicle__version__brandfamily: null,
              vehicle__version__model__name: null,
              vehicle__version: null,
            },
          };
        case "vehicle__version__brandfamily":
          return {
            ...state,
            stockPriceParams: {
              ...state.stockPriceParams,
              [action.payload.key]: action.payload.value,
              vehicle__version__model__name: null,
              vehicle__version: null,
            },
          };
        case "vehicle__version__model__name":
          return {
            ...state,
            stockPriceParams: {
              ...state.stockPriceParams,
              [action.payload.key]: action.payload.value,
              vehicle__version: null,
            },
          };
        default:
          return {
            ...state,
            stockPriceParams: {
              ...state.stockPriceParams,
              [action.payload.key]: action.payload.value,
            },
          };
      }
      // return {
      //   ...state,
      //   stockPriceParams: {
      //     ...state.stockPriceParams,
      //     [action.payload.key]: action.payload.value,
      //   },
      // };
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },

    // Comments List API
    getCommentList: (state) => {
      if (state.commentParams?.page == 1) {
        return {
          ...state,
          loading: true,
          commentList: [],
          model: true,
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },

    getGalleryList: (state) => {
      if (state.galleryParams?.page == 1) {
        return {
          ...state,
          galleryList: [],
          galleryModel: true,
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },

    commentListSuccessful: (state, action) => {
      var response = action.payload;
      console.log("respone..", response);
      var list = [];
      if (state.commentParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.commentParams?.page_size
      );
      return {
        ...state,
        commentList: list,
        commentCount: action.payload.count,
        loading: false,
        commentParams: {
          ...state.commentParams,
          no_of_pages: noofpages,
        },
      };
    },

    galleryListSuccessful: (state, action) => {
      const response = action.payload;
      let imageList = [];
      // Collect all the non-null image URLs
      Object.keys(response).forEach((key) => {
        if (key.includes("vehicle")) {
          imageList.push(response[key]); // Push both null and non-null values
        }
      });
      const noofpages = state.galleryParams?.no_of_pages || 1;

      
      var oldImgs = [
        {
          ...action.payload.vehicleback_image,
          imageType: "Vehicle Back Image",
        },
        {
          ...action.payload.vehiclefront_image,
          imageType: "Vehicle Front Image",
        },
        {
          ...action.payload.vehicleleft_image,
          imageType: "Vehicle Left Image",
        },
        {
          ...action.payload.vehicleright_image,
          imageType: "Vehicle Right Image",
        },

        {
          ...action.payload.vehicle_odomerter_image,
          imageType: "Vehicle Odomerter Image",
        },

        // { ...action.payload.rcfront_image, imageType: "RC Front Image" },
        // { ...action.payload.rcback_image, imageType: "RC Back Image" },
      ];
      var NewImg = [
        {
          ...action.payload.vehicleback_image_latest,
          imageType: "Latest Vehicle Back Image",
        },
        {
          ...action.payload.vehiclefront_image_latest,
          imageType: "Latest Vehicle Front Image",
        },
        {
          ...action.payload.vehicleleft_image_latest,
          imageType: "Latest Vehicle Left Image",
        },
        {
          ...action.payload.vehicleright_image_latest,
          imageType: "Latest Vehicle Right Image",
        },

        {
          ...action.payload.vehicle_odomerter_image_latest,
          imageType: "Latest Vehicle Odomerter Image",
        },
        // { ...action.payload.rcfront_image, imageType: "RC Front Image" },
        // { ...action.payload.rcback_image, imageType: "RC Back Image" },
      ]

      return {
        ...state,
        galleryList: action.payload.vehiclefront_image_latest ? NewImg  : oldImgs, // Store the array of images
        galleryParams: {
          ...state.galleryParams,
          no_of_pages: noofpages, // Ensure `noofpages` is defined properly
        },
      };
    },

    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          commentList: [],
        };
      } else {
        return {
          ...state,
          model: action.payload,
          commentList: [],
        };
      }
    },

    isGalleryModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          galleryModel: action.payload,
          galleryList: [],
        };
      } else {
        return {
          ...state,
          galleryModel: action.payload,
          galleryList: [],
        };
      }
    },

    setCommentsParams: (state, action) => {
      return {
        ...state,
        commentParams: action.payload,
      };
    },

    // Rating list API
    getRatingList: (state) => {
      if (state.ratingParams?.page == 1) {
        return {
          ...state,
          loading: true,
          ratingList: [],
          ratingViewModel: true,
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    ratingListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.ratingParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.ratingParams?.page_size
      );
      return {
        ...state,
        ratingList: list,
        ratingCount: action.payload.count,
        loading: false,
        ratingParams: {
          ...state.ratingParams,
          no_of_pages: noofpages,
        },
      };
    },
    setRatingParams: (state, action) => {
      return {
        ...state,
        ratingParams: action.payload,
      };
    },
    isRatingViewModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          ratingViewModel: action.payload,
          ratingList: [],
        };
      } else {
        return {
          ...state,
          ratingViewModel: action.payload,
          ratingList: [],
        };
      }
    },
    // Update Minimumsale Amount
    InputChangeValue: (state, action) => {
      return {
        ...state,
        minimumSaleObj: {
          ...state.minimumSaleObj,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isMiniAmountModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          miniModel: action.payload.status,
          minimumSaleObj: {
            id: action.payload.id,
          },
        };
      } else {
        return {
          ...state,
          miniModel: action.payload.status,
          minimumSaleObj: {
            id: action.payload.id,
          },
        };
      }
    },
    // Update Special Incentive Amount
    IncentiveInputChangeValue: (state, action) => {
      return {
        ...state,
        incentiveObj: {
          ...state.incentiveObj,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isIncentiveModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          incentiveModel: action.payload.status,
          incentiveObj: {
            id: action.payload.id,
          },
        };
      } else {
        return {
          ...state,
          incentiveModel: action.payload.status,
          incentiveObj: {
            id: action.payload.id,
          },
        };
      }
    },
    // Guarage Repair
    isGuarageModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          guarageModel: action.payload.status,
          guarageData: {
            purchase_id: action.payload.purchase_id,
          },
        };
      } else {
        return {
          ...state,
          guarageModel: action.payload.status,
          guarageData: {
            purchase_id: action.payload.purchase_id,
          },
        };
      }
    },

    isQuickShellModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          quickShellModel: action.payload.status,
          qsPriceObj: {
            id: action?.payload?.id,
            data: action?.payload?.data,
            qs_status: action?.payload?.data?.qs_status,
            // qs_price: action?.payload?.qs_price ? action?.payload?.qs_price : "",
            // qs_discounted_price: action?.payload?.qs_discounted_price ? action?.payload?.qs_discounted_price : "",
          },
        };
      }
    },
    isGuarageViewModel: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          guarageViewModel: action.payload,
          guarageData: {},
        };
      } else {
        return {
          ...state,
          guarageViewModel: action.payload,
          guarageData: {},
        };
      }
    },
    GuarageInputChangeValue: (state, action) => {
      return {
        ...state,
        guarageData: {
          ...state.guarageData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    quickShellInputChangeValue: (state, action) => {
      return {
        ...state,
        qsPriceObj: {
          ...state.qsPriceObj,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    updateGuarageRepair: (state, action) => {
      return {
        ...state,
        guarageLoading: true,
      };
    },

    addQsPrice: (state, action) => {
      return {
        ...state,
        quickSellLoading: true,
      };
    },

    updateOutOfStock: (state, action) => {
      return {
        ...state,
        quickSellLoading: true,
      };
    },
    deleteQs: (state, action) => {
      return {
        ...state,
        quickSellLoading: true,
      };
    },
    addedSuccess: (state, action) => {
      return {
        ...state,
        quickSellLoading: action?.payload?.status,
      };
    },
    getGuarageRepairById: (state, action) => {
      return {
        ...state,
        guarageLoading: true,
        guarageData: {},
      };
    },
    GuarageRepairByIdSuccessful: (state, action) => {
      return {
        ...state,
        guarageLoading: false,
        // guarageModel: true,
        guarageData: {
          id: action.payload.response.id,
          note: action.payload.response.note,
          repairestimation: action.payload.response.repairestimation,
          workstatus: action.payload.response.workstatus,
          purchase_id: action.payload.response.purchase?.id,
          date:
            action.payload.response.date == null
              ? null
              : moment(action.payload.response.date),
        },
        guarageModel: action.payload.type == "update" ? true : false,
        guarageViewModel: action.payload.type == "view" ? true : false,
        guarageViewData: action.payload.response,
      };
    },
    updatIsRepair: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    checkRTA: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getStockPrice,
  stockPriceSuccessful,
  setStockPriceParams,
  isDrawerVisible,
  FilterInputChangeValue,
  getStockPriceCount,
  stockPriceCountSuccessful,
  getCommentList,
  getGalleryList,
  commentListSuccessful,
  galleryListSuccessful,
  setCommentsParams,
  isModelVisible,
  getRatingList,
  isGalleryModelVisible,
  ratingListSuccessful,
  setRatingParams,
  isRatingViewModelVisible,
  InputChangeValue,
  isMiniAmountModelVisible,
  IncentiveInputChangeValue,
  isIncentiveModelVisible,
  isGuarageModelVisible,
  isQuickShellModelVisible,
  GuarageInputChangeValue,
  quickShellInputChangeValue,
  updateGuarageRepair,
  addQsPrice,
  updateOutOfStock,
  deleteQs,
  addedSuccess,
  getGuarageRepairById,
  GuarageRepairByIdSuccessful,
  updatIsRepair,
  isGuarageViewModel,
  checkFilterStatus,
  checkRTA,
  apiError,
} = stockPriceSlice.actions;

export default stockPriceSlice.reducer;
